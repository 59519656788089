/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
@import 'src/app/shared/common';

/*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
.mat-form-field-subscript-wrapper {
    margin-top: 0px !important;
}